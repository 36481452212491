// 严凯 V2.2.0 // 刘志坤 V2.3.0
<template>
  <div class="page-add" v-show="loadings">
    <!-- <section> -->
    <a-form ref="formRef" :rules="rules" :model="modalVal" class="form-add">
      <!-- OCR识别 -->
      <a-form-item label="OCR识别" name="" class="form-item" :labelCol="{ span: 4 }">
        <div v-if="modalVal.ocrType === 1">
          <span style="color: #47D300FF;">已开启</span>（可前往<span
            style="color: #C3161CFF;cursor: pointer;"
            @click="$router.push('/businessManage/flowManage/business')"
            >用印流程管理</span
          >中配置，有效期至{{ modalVal.ocrEndTime }}）
        </div>
        <div v-else><span style="color: #00000040;">未开启</span><span>（如需开启OCR识别功能，请联系客服）</span></div>
      </a-form-item>
      <!-- 指纹录入时验证 -->
      <a-form-item label="指纹录入时验证" name="" class="form-item" :labelCol="{ span: 5 }">
        <a-switch v-model:checked="modalVal.fingerprintVerify" />
        <a-tooltip placement="top" class="tips-text">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >录入完成需要验证指纹，仅针对天玺设备生效</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
        </a-tooltip>
      </a-form-item>
      <a-form-item label="" class="form-item">
        <section style="margin-left: 166px;margin-top:120px">
          <a-button class="cancel-btn" @click="handleCancel" style="margin-right:16px">取消</a-button>
          <a-button class="submit-btn" @click="submit" :loading="loading">确定</a-button>
        </section>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, onMounted, toRefs, createVNode } from 'vue'
import { useRouter } from 'vue-router'
import { getConfigure, putConfigure } from '@/apis/businessManage'
import { cmsNotice } from '@/utils/utils'
import { QuestionCircleFilled } from '@ant-design/icons-vue'
import '@/style/page-add.scss'

export default defineComponent({
  components: {
    QuestionCircleFilled
  },
  setup(props) {
    const router = useRouter()
    const formRef = ref()
    const modalVal = reactive({
      enterpriseId: undefined,
      fingerprintVerify: false,
      ocrType: false,
      finishSealPush: false
    })
    const state = reactive({
      loading: false,
      loadings: false
    })
    const rules = ref()

    const submit = () => {
      state.loading = true
      formRef.value
        .validate()
        .then(() => dataSubmit(modalVal))
        .catch(() => (state.loading = false))
    }
    const handleCancel = () => {
      getConfigureData()
      // formRef.value.resetFields()modalVal
    }
    // TODO:表单提交增加抓拍和快捷用印参数
    const dataSubmit = async value => {
      console.log('啥玩意这是=====', value)
      const params = {
        fingerprintVerify: value.fingerprintVerify ? 1 : 2, //指纹录入校验
        finishSealPush: value.finishSealPush ? 1 : 2 //完成用印推送是否开启
      }

      const res = await putConfigure(params)
      if (res.success) {
        cmsNotice('success', '设置成功')
      }
      // console.log(res.data.printTime, '11111')
      state.loading = false
    }
   
    const getConfigureData = async () => {
      const res = await getConfigure()
      console.log('展示列表=====', res)
      if (res.success) {
        modalVal.ocrType = res.data.ocrType
        modalVal.ocrEndTime = res.data.ocrEndTime
        modalVal.finishSealPush = res.data.finishSealPush === 1
        modalVal.fingerprintVerify = res.data.fingerprintVerify === 1
       
        console.log('0000000', modalVal)
        state.loadings = true
      }
    }

    onMounted(() => {
      getConfigureData()
    })

    return {
      submit,
      formRef,
      modalVal,
      rules,
      handleCancel,
      router,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="scss" scoped>
.page-add {
  width: 100vw;
  margin: 0 auto;
  min-height: 592px;
}

:deep(.ant-form-item-label) {
  width: 102px;
}

.printer {
  width: 468px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 24px 1px 10px;

  &::-webkit-scrollbar {
    display: block;
  }

  .pt-tag {
    // width: 90px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .pt-name {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 9px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }

  .pt-close {
    font-size: 10px;
  }

  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
}

.line-tooltip {
  :deep(.ant-tooltip-content) {
    width: 360px;
  }
}

// /deep/.ant-modal-body{
//   width: 1000px !important;
// }
.tips-text {
  text-align: center;
}
.seal-pattern-notice {
  font-size: 12px;
  margin-left: 7px;
}
</style>
